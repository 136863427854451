import request from '@/utils/request'

export function updateShareCount(parameter) {
  return request({
    url: '/integral/member/update/share/count',
    method: 'post',
    data: parameter
  })
}

export function updateMemberCash(parameter) {
  return request({
    url: '/member/update/cash',
    method: 'post',
    data: parameter
  })
}

export function updateFillStatus(parameter) {
  return request({
    url: '/integral/member/update/fill/status',
    method: 'post',
    data: parameter
  })
}

export function updateUserType(parameter) {
  return request({
    url: '/integral/member/update/fill/status',
    method: 'post',
    data: parameter
  })
}

export function updateExchange(parameter) {
  return request({
    url: '/integral/member/update/exchange',
    method: 'post',
    data: parameter
  })
}

export function updateCashBalance(parameter) {
  return request({
    url: '/integral/member/update/cash',
    method: 'post',
    data: parameter
  })
}

export function queryOperators1(parameter) {
  return request({
    url: '/user',
    method: 'post',
    data: parameter
  })
}
export function getOperator(parameter) {
  return request({
    url: '/user/',
    method: 'get',
    params: parameter
  })
}

export function queryOperators(parameter) {
  return request({
    url: '/integral/member/page',
    method: 'post',
    data: parameter
  })
}

export function putPause(parameter) {
  return request({
    url: '/member',
    method: 'put',
    data: parameter
  })
}

export function putExchange(parameter) {
  return request({
    url: '/member',
    method: 'put',
    data: parameter
  })
}

export function removeOperator(parameter) {
  return request({
    url: '/member/delete/' + parameter.id,
    method: 'delete'
  })
}

export function memberHeardData(parameter) {
  return request({
    url: '/integral/member/statistics',
    method: 'get',
    params: parameter
  })
}

export function getOperatorDetail(parameter) {
  return request({
    url: '/integral/member/detail/'+parameter.id,
    method: 'get',
    params: parameter
  })
}

export function getMemberCash(parameter) {
  return request({
    url: '/integral/member/cash',
    method: 'get',
    params: parameter
  })
}

export function updateValueBalance(parameter) {
  return request({
    url: '/integral/member/update/cumulative',
    method: 'post',
    data: parameter
  })
}





